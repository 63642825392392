import * as React from 'react'
import styled from 'styled-components'
import { PageProps, graphql } from 'gatsby'
import { MDXProvider, MDXProviderComponentsProp } from '@mdx-js/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'

import MainLayout from 'components/layouts/Main'
import Container from 'components/common/Container'
import ContentPanel from 'components/common/ContentPanel'

import appContent from 'content/app.json'
import withAuth from 'lib/utils/withAuth'

const ContentContainer = styled(Container)`
  text-align: left;
  flex-direction: column;
  justify-content: initial;
  align-items: initial;
`

const RenderContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  color: black;
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;
  justify-content: flex-start;
`

type ReadinessTestProps = {
  data: {
    mdx: {
      body: string
      frontmatter: {
        headerTitle: string
      }
    }
  }
}

const components: MDXProviderComponentsProp = {}

type ReadinessTestPageProps = PageProps & ReadinessTestProps

const ReadinessTestPage: React.FC<ReadinessTestPageProps> = ({ location, data }) => {
  return (
    <MainLayout
      backgroundColor={appContent.backgroundColor}
      showTabBar={!!appContent.tabBar}
      currentPath={location.pathname}
      pageTitle={data.mdx.frontmatter.headerTitle}
      showBackButton
      fixedHeader
      content={
        <ContentContainer>
          <ContentPanel>
            <MDXProvider components={components}>
              <RenderContainer>
                <MDXRenderer>{data.mdx.body}</MDXRenderer>
              </RenderContainer>
            </MDXProvider>
          </ContentPanel>
        </ContentContainer>
      }
    />
  )
}

export const query = graphql`
  query ReadinessQuery {
    mdx(frontmatter: { name: { eq: "readiness-test" } }) {
      body
      frontmatter {
        headerTitle
      }
    }
  }
`

export default withAuth(ReadinessTestPage)
