import * as React from 'react'
import styled from 'styled-components'

import Container from './Container'

import colors from 'lib/utils/colors'

const ContentPanelContainer = styled(Container)`
  background-color: ${colors.White};
  min-height: 600px;
  border-radius: 24px;
  padding: 28px 18px 90px;
  flex-direction: column;
  max-width: 768px;
  min-width: 320px;
  width: 100vw;
`

const PanelHeaderContainer = styled(Container)`
  font-size: 21px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: ${colors.Black};
  padding-left: 2px;
  margin-bottom: 16px;
  text-align: left;
  min-height: 52px;
  align-items: center;
  justify-content: flex-start;
`

const ContentDetailsContainer = styled(Container)`
  flex: 1;
`

type Props = {
  header?: string
  children?: React.ReactNode
}

const ContentPanel: React.FC<Props> = ({ header, children }) => {
  return (
    <ContentPanelContainer>
      {header && <PanelHeaderContainer>{header}</PanelHeaderContainer>}
      <ContentDetailsContainer>{children}</ContentDetailsContainer>
    </ContentPanelContainer>
  )
}

export default ContentPanel
